import { createAsyncThunk, createSelector, createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import membershipApi from 'api/membership.api';
import ISlidingFeeProgram, {
    ICodeCategoryExclusion,
    ICodeExclusion,
    ICodeGroupExclusion,
    ISlidingFeePlan,
} from 'api/models/slidingFee.model';
import { push } from 'connected-react-router';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { map, sortBy } from 'lodash';
import { batch } from 'react-redux';
import { AppThunk, RootState } from 'state/store';
import { isDateBetween } from 'utils/isDateBetween';

import { IDropdownOption } from '@fluentui/react';
import { isAfter, isBefore } from 'date-fns';
import dateOnly from 'utils/dateOnly';
import { IAthenaSlidingFeePlan, IAthenaSlidingFeeProgram } from 'api/models/athena-sliding-fee.model';
import { Loading } from 'components';

export enum SlidingFeePlanView {
    Active = 'Active',
    Future = 'Future',
    Expired = 'Expired',
}

export type SlidingFeeState = {
    isExpanded: boolean;
    isProgramPanelOpen: boolean;
    isProgramPlanPanelOpen: boolean;
    isCodeExclusionPanelOpen: boolean;
    isGroupExclusionPanelOpen: boolean;
    isCategoryExclusionPanelOpen: boolean;

    toggleDeletedCategoryExclusions: boolean;
    toggleDeletedRangeExclusions: boolean;
    toggleDeletedCodeExclusions: boolean;
    plansView: SlidingFeePlanView;

    showProgramsHistory: boolean;

    programs: {
        data?: Dictionary<ISlidingFeeProgram>;
        athenaOnePrograms?: Record<number, IAthenaSlidingFeeProgram>;
        loading: LoadingStatuses;
        loadingAthenaOnePrograms: LoadingStatus;
        loadingRefreshAthenaOnePrograms: LoadingStatus;
    };
    selectedProgram: { data?: ISlidingFeeProgram; loading: LoadingStatuses };
    selectedProgramPlan: {
        data?: ISlidingFeePlan;
        loading: LoadingStatuses;
        isNew?: boolean;
        insertIndex?: number;
    };
    selectedCategoryExclusion: {
        data?: ICodeCategoryExclusion;
        loading: LoadingStatuses;
    };
    selectedCodeExclusion: {
        data?: ICodeExclusion;
        loading: LoadingStatuses;
    };
    selectedGroupExclusion: {
        data?: ICodeGroupExclusion;
        loading: LoadingStatuses;
    };
};

const initialState: SlidingFeeState = {
    isExpanded: true,
    isProgramPanelOpen: false,
    isProgramPlanPanelOpen: false,
    isGroupExclusionPanelOpen: false,
    isCodeExclusionPanelOpen: false,
    isCategoryExclusionPanelOpen: false,
    toggleDeletedCategoryExclusions: true,
    toggleDeletedRangeExclusions: true,
    toggleDeletedCodeExclusions: true,
    plansView: SlidingFeePlanView.Active,

    showProgramsHistory: false,

    programs: {
        data: undefined,
        loading: LoadingStatus.Idle,
        loadingRefreshAthenaOnePrograms: LoadingStatus.Idle,
        loadingAthenaOnePrograms: LoadingStatus.Idle,
    },
    selectedProgram: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },
    selectedProgramPlan: {
        data: undefined,
        loading: LoadingStatus.Idle,
        insertIndex: undefined,
    },
    selectedCategoryExclusion: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },
    selectedCodeExclusion: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },
    selectedGroupExclusion: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },
};

export enum ExclusionType {
    Category = 'Category',
    Code = 'Code',
}
export const getSlidingFeePrograms = createAsyncThunk<
    Dictionary<ISlidingFeeProgram>,
    {
        tenantId: string;
    }
>('getSlidingFeePrograms', async ({ tenantId }) => {
    const response = await membershipApi.getSlidingFeePrograms(tenantId);
    return response.data;
});

export const getSlidingFeeProgramById = createAsyncThunk<
    ISlidingFeeProgram,
    {
        tenantId: string;
        programId: string;
    }
>('getSlidingFeeProgramById', async ({ tenantId, programId }) => {
    const response = await membershipApi.getSlidingFeeProgramById(tenantId, programId);
    return response.data;
});

export const createSlidingFeeProgram = createAsyncThunk<
    ISlidingFeeProgram,
    {
        tenantId: string;
        program: ISlidingFeeProgram;
    }
>('createSlidingFeeProgram', async ({ tenantId, program }) => {
    const res = await membershipApi.createSlidingFeeProgram(tenantId, program);
    // const res = await membershipApi.getSlidingFeePrograms(tenantId);
    return res.data;
});

export const updateSlidingFeeProgram = createAsyncThunk<
    ISlidingFeeProgram,
    {
        tenantId: string;
        program: ISlidingFeeProgram;
    }
>('updateSlidingFeeProgram', async ({ tenantId, program }) => {
    await membershipApi.updateSlidingFeeProgram(tenantId, program);
    const response = await membershipApi.getSlidingFeeProgramById(tenantId, program.id);
    return response.data;
});

export const getAthenaSlidingFeePrograms = createAsyncThunk<
    Record<number, IAthenaSlidingFeeProgram>,
    {
        tenantId: string;
    }
>('getAthenaSlidingFeePrograms', async ({ tenantId }) => {
    const response = await membershipApi.getAthenaSlidingFeePrograms(tenantId);
    return response.data;
});

export const getRefreshAethenaSlidingFeePrograms = createAsyncThunk<
    object,
    {
        tenantId: string;
    }
>('getRefreshAethenaSlidingFeePrograms', async ({ tenantId }) => {
    const response = await membershipApi.getRefreshAthenaSlidingFeePrograms(tenantId);
    return response.data;
});

export const updateSlidingFeeProgramPlan =
    (tenantId: string, program: ISlidingFeeProgram): AppThunk =>
    (dispatch): void => {
        batch(() => {
            dispatch(updateSlidingFeeProgram({ tenantId, program }));
            dispatch(push(`/${tenantId}/sliding-fee/${program.id}`));
        });
    };

export const getAthenaSlidingFeeProgramsForCurrentTenant =
    (data: { tenantid: string }): AppThunk<void> =>
    (dispatch, getState) => {
        const tenantId = getState().tenant.currentTenant.data?.id;
        if (tenantId && tenantId === data.tenantid) dispatch(getAthenaSlidingFeePrograms({ tenantId }));
    };

const slidingFeeSlice = createSlice({
    name: 'sliding-fee',
    initialState,
    reducers: {
        setExpanded(state, action: PayloadAction<boolean>) {
            state.isExpanded = action.payload;
        },
        setIsProgramPanelOpen(state, action: PayloadAction<boolean>) {
            state.isProgramPanelOpen = action.payload;
        },
        setIsProgramPlanPanelOpen(state, action: PayloadAction<boolean>) {
            state.isProgramPlanPanelOpen = action.payload;
        },
        setIsCodeExclusionPanelOpen(state, action: PayloadAction<boolean>) {
            state.isCodeExclusionPanelOpen = action.payload;
        },
        setIsGroupExclusionPanelOpen(state, action: PayloadAction<boolean>) {
            state.isGroupExclusionPanelOpen = action.payload;
        },
        setIsCategoryExclusionPanelOpen(state, action: PayloadAction<boolean>) {
            state.isCategoryExclusionPanelOpen = action.payload;
        },
        setSelectedProgramPlan(
            state,
            action: PayloadAction<{
                plan?: ISlidingFeePlan;
                isNew?: boolean;
                insertIndex?: number;
            }>,
        ) {
            const { plan, insertIndex, isNew } = action.payload;
            state.selectedProgramPlan.data = plan;
            state.selectedProgramPlan.isNew = isNew;
            state.selectedProgramPlan.insertIndex = insertIndex;
        },

        setSelectedCategoryExclusion(
            state,
            action: PayloadAction<{
                exclusion?: ICodeCategoryExclusion;
            }>,
        ) {
            const { exclusion } = action.payload;
            state.selectedCategoryExclusion.data = exclusion;
        },
        toggleDeletedCategoryExclusions: (state) => {
            state.toggleDeletedCategoryExclusions = !state.toggleDeletedCategoryExclusions;
        },
        toggleDeletedRangeExclusions: (state) => {
            state.toggleDeletedRangeExclusions = !state.toggleDeletedRangeExclusions;
        },
        toggleDeletedCodeExclusions: (state) => {
            state.toggleDeletedCodeExclusions = !state.toggleDeletedCodeExclusions;
        },
        setSlidingFeePlansView: (state, { payload }: PayloadAction<SlidingFeePlanView>) => {
            state.plansView = payload;
        },
        setSelectedCodeExclusion(
            state,
            action: PayloadAction<{
                exclusion?: ICodeExclusion;
            }>,
        ) {
            const { exclusion } = action.payload;
            state.selectedCodeExclusion.data = exclusion;
        },
        setSelectedGroupExclusion(
            state,
            action: PayloadAction<{
                exclusion?: ICodeGroupExclusion;
            }>,
        ) {
            const { exclusion } = action.payload;
            state.selectedGroupExclusion.data = exclusion;
        },
        cleanupSelectedProgram(state) {
            state.selectedProgram.data = undefined;
            state.selectedProgram.loading = LoadingStatus.Idle;
        },
        cleanupSelectedProgramPlan(state) {
            state.selectedProgramPlan.data = undefined;
            state.selectedProgramPlan.loading = LoadingStatus.Idle;
        },
        updateSelectedProgram(
            state,
            action: PayloadAction<{
                program?: ISlidingFeeProgram;
            }>,
        ) {
            const { program } = action.payload;
            state.selectedProgram.data = program;
        },
        updateSelectedProgramProp(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string | string[] | ISlidingFeePlan[] | boolean | number | undefined;
                path: keyof ISlidingFeeProgram;
            }>,
        ) {
            const { value, path } = payload;
            if (state.selectedProgram.data) (state.selectedProgram.data[path] as unknown) = value;
        },
        clearSelectedProgramPlansAthenaOnePlanMappings: (state) => {
            if (state.selectedProgram.data?.plans) {
                state.selectedProgram.data.plans = state.selectedProgram.data.plans.map((p) => ({ ...p, athenaPlanId: '' }));
            }
        },
        updateSelectedProgramPlan: (state, { payload }: PayloadAction<{ planId: string; data: ISlidingFeePlan }>) => {
            if (state.selectedProgram.data?.plans) {
                const { planId, data } = payload;
                const indexOfPlan = state.selectedProgram.data.plans.findIndex((p) => p.id === planId);
                if (indexOfPlan > -1) {
                    state.selectedProgram.data.plans[indexOfPlan] = data;
                }
            }
        },
        toggleShowProgramHistory: (state) => {
            state.showProgramsHistory = !state.showProgramsHistory;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getSlidingFeePrograms.pending, (state) => {
                state.programs.loading = LoadingStatus.Pending;
            })
            .addCase(getSlidingFeePrograms.fulfilled, (state, action) => {
                const programs = action.payload;
                state.programs.loading = LoadingStatus.Completed;
                state.programs.data = programs;
            })
            .addCase(getSlidingFeePrograms.rejected, (state) => {
                state.programs.loading = LoadingStatus.Failed;
            })
            .addCase(getAthenaSlidingFeePrograms.pending, (state) => {
                state.programs.loadingAthenaOnePrograms = LoadingStatus.Pending;
            })
            .addCase(getAthenaSlidingFeePrograms.fulfilled, (state, action) => {
                const programs = action.payload;
                state.programs.loadingAthenaOnePrograms = LoadingStatus.Completed;
                state.programs.athenaOnePrograms = programs;
            })
            .addCase(getAthenaSlidingFeePrograms.rejected, (state) => {
                state.programs.loadingAthenaOnePrograms = LoadingStatus.Failed;
            })
            .addCase(getSlidingFeeProgramById.pending, (state) => {
                state.selectedProgram.loading = LoadingStatus.Pending;
            })
            .addCase(getSlidingFeeProgramById.fulfilled, (state, action) => {
                const program = action.payload;
                state.selectedProgram.loading = LoadingStatus.Completed;
                state.selectedProgram.data = program;
            })
            .addCase(getSlidingFeeProgramById.rejected, (state) => {
                state.selectedProgram.loading = LoadingStatus.Failed;
            })
            .addCase(createSlidingFeeProgram.pending, (state) => {
                state.programs.loading = LoadingStatus.Pending;
            })
            .addCase(createSlidingFeeProgram.fulfilled, (state, action) => {
                const programs = action.payload;
                state.programs.loading = LoadingStatus.Completed;
                if (state.programs.data) state.programs.data[programs.id] = programs;
                state.isProgramPanelOpen = false;
            })
            .addCase(createSlidingFeeProgram.rejected, (state) => {
                state.selectedProgram.loading = LoadingStatus.Failed;
            })
            .addCase(updateSlidingFeeProgram.pending, (state) => {
                state.selectedProgram.loading = LoadingStatus.Pending;
                state.selectedProgramPlan.loading = LoadingStatus.Pending;
            })
            .addCase(updateSlidingFeeProgram.fulfilled, (state, action) => {
                const program = action.payload;
                state.selectedProgram.loading = LoadingStatus.Completed;
                state.selectedProgram.data = program;

                state.selectedProgramPlan.data = undefined;
                state.selectedProgramPlan.loading = LoadingStatus.Completed;
                state.selectedProgramPlan.isNew = undefined;
                state.selectedProgramPlan.insertIndex = undefined;
                state.isProgramPlanPanelOpen = false;

                if (state.programs.data) {
                    state.programs.data[program.id] = program;
                }
            })
            .addCase(updateSlidingFeeProgram.rejected, (state) => {
                state.selectedProgram.loading = LoadingStatus.Failed;
                state.selectedProgramPlan.loading = LoadingStatus.Failed;
            })
            .addCase(getRefreshAethenaSlidingFeePrograms.pending, (state) => {
                state.programs.loadingRefreshAthenaOnePrograms = LoadingStatus.Pending;
            })
            .addCase(getRefreshAethenaSlidingFeePrograms.fulfilled, (state) => {
                state.programs.loadingRefreshAthenaOnePrograms = LoadingStatus.Completed;
            })
            .addCase(getRefreshAethenaSlidingFeePrograms.rejected, (state) => {
                state.programs.loadingRefreshAthenaOnePrograms = LoadingStatus.Failed;
            });
    },
});

const { reducer, actions } = slidingFeeSlice;

export const {
    setExpanded,
    setIsProgramPanelOpen,
    setIsProgramPlanPanelOpen,
    setIsCodeExclusionPanelOpen,
    setIsCategoryExclusionPanelOpen,
    setIsGroupExclusionPanelOpen,
    setSelectedProgramPlan,
    setSelectedCategoryExclusion,
    setSelectedCodeExclusion,
    setSelectedGroupExclusion,
    cleanupSelectedProgram,
    cleanupSelectedProgramPlan,
    updateSelectedProgram,
    updateSelectedProgramProp,
    updateSelectedProgramPlan,
    clearSelectedProgramPlansAthenaOnePlanMappings,

    toggleDeletedCategoryExclusions,
    toggleDeletedRangeExclusions,
    toggleDeletedCodeExclusions,
    setSlidingFeePlansView,

    toggleShowProgramHistory,
} = actions;

export const selectExpanded = (state: RootState) => state.slidingFee.isExpanded;

export const selectIsProgramPanelOpen = (state: RootState) => state.slidingFee.isProgramPanelOpen;
export const selectPrograms = (state: RootState) => state.slidingFee.programs.data;
export const selectAthenaOnePrograms = (state: RootState) => state.slidingFee.programs.athenaOnePrograms || {};
export const selectProgramsHistory = (state: RootState) => state.slidingFee.showProgramsHistory;

export const selectProgramsLoading = (state: RootState) => state.slidingFee.programs.loading;
export const selectAthenaOneProgramsLoading = (state: RootState) => state.slidingFee.programs.loading;
export const selectRefreshAthenaOneProgramsLoading = (state: RootState) =>
    state.slidingFee.programs.loadingRefreshAthenaOnePrograms;

export const selectAthenaOneProgramsAsList = createSelector([selectAthenaOnePrograms], (programs) =>
    map(programs).filter((p) => !p.isDeleted),
);

export const selectAthenaOneProgramsOptions = createSelector(selectAthenaOneProgramsAsList, (programs) =>
    programs.map((p) => ({ key: p.id, text: p.name || 'Unknown Program' }) as IDropdownOption),
);

export const selectProgramsAsList = createSelector([selectPrograms, selectProgramsHistory], (data, showHistory) => {
    const list = map(sortBy(data, ['isDeleted'])) as ISlidingFeeProgram[];
    return showHistory ? list : list.filter((program) => program !== undefined).filter((program) => !program?.isDeleted);
});

export const selectSelectedProgram = (state: RootState) => state.slidingFee.selectedProgram.data;
export const selectSelectedProgramPlans = (state: RootState) => state.slidingFee.selectedProgram.data?.plans || [];

export const selectSelectedProgramAthenaOnePlans = createSelector(
    [selectSelectedProgram, selectAthenaOnePrograms],
    (selectedProgram, athenaPrograms) => {
        if (!selectedProgram?.athenaProgramId || !athenaPrograms) return [];
        return athenaPrograms[+selectedProgram.athenaProgramId]?.plans || [];
    },
);

export const selectSelectedProgramAthenaOnePlansData = createSelector([selectSelectedProgramAthenaOnePlans], (plans) => {
    const lookup: Record<string, IAthenaSlidingFeePlan> = {};

    plans.forEach((p) => {
        if (p.id) lookup[p.id] = p;
    });

    return lookup;
});

export const selectSelectedProgramAthenaOnePlanOptions = createSelector([selectSelectedProgramAthenaOnePlans], (plans) =>
    plans.map((p) => ({ key: p.id, title: p.name || 'Unkown Plan', text: p.name || 'Unkown Plan' }) as IDropdownOption),
);

export const selectLookupAthenaOnePlanOptionsForPlans = createSelector(
    [selectSelectedProgramPlans, selectSelectedProgramAthenaOnePlanOptions, selectSelectedProgramAthenaOnePlansData],
    (plans, options, athenaOnePlansData) => {
        const lookup: Record<string, IDropdownOption[]> = {};
        const selectedAOnePlanIds = new Set(plans.filter((p) => !!p.athenaPlanId).map((p) => p.athenaPlanId));
        const newOptions = options.filter((o) => !selectedAOnePlanIds.has(o.key as string));

        plans.forEach((plan) => {
            lookup[plan.id] = [...newOptions];
            if (plan.athenaPlanId && !!athenaOnePlansData[plan.athenaPlanId]) {
                lookup[plan.id].unshift({
                    key: plan.athenaPlanId,
                    text: athenaOnePlansData[plan.athenaPlanId]?.name || 'Unknown Plan',
                });
            }
        });

        return lookup;
    },
);

export const selectProgramsPlanAsOption = createSelector(selectSelectedProgramPlans, (data) => {
    if (data && data.length) {
        const option: IDropdownOption[] = data.map((plan) => ({
            key: plan.name ?? '',
            text: plan.name ?? '',
            title: plan.name ?? '',
        }));
        return option;
    }
    return [];
});

export const selectSlidingFeePlansView = (state: RootState) => state.slidingFee.plansView;

function getSlidingFeePlanActive(plan: ISlidingFeePlan) {
    return isDateBetween({
        dateToCheck: new Date().toISOString(),
        start: plan.effectiveDate,
        end: plan.expirationDate,
    });
}

function getFutureSlidingFeePlan(plan: ISlidingFeePlan) {
    if (!plan.effectiveDate) return true;
    return isBefore(new Date(dateOnly(new Date().toISOString())), new Date(dateOnly(plan.effectiveDate)));
}

function getExpiredSlidingFeePlan(plan: ISlidingFeePlan) {
    if (getFutureSlidingFeePlan(plan) || !plan.expirationDate) return false;
    return isAfter(new Date(dateOnly(new Date().toISOString())), new Date(dateOnly(plan.expirationDate)));
}

export const selectSelectedExpiredPlanList = createSelector(selectSelectedProgramPlans, (plans) => {
    return plans.filter(getExpiredSlidingFeePlan);
});

export const selectSelectedFuturePlanList = createSelector(selectSelectedProgramPlans, (plans) => {
    return plans.filter(getFutureSlidingFeePlan);
});

export const selectSelectedActivePlanList = createSelector(selectSelectedProgramPlans, (plans) => {
    return plans.filter(getSlidingFeePlanActive);
});

export const selectSlidingFeelPlanViewPlanList = createSelector(
    selectSelectedActivePlanList,
    selectSelectedFuturePlanList,
    selectSelectedExpiredPlanList,
    selectSlidingFeePlansView,
    (active, future, expired, view) => {
        switch (view) {
            case SlidingFeePlanView.Active:
                return active;
            case SlidingFeePlanView.Future:
                return future;
            case SlidingFeePlanView.Expired:
                return expired;
        }
    },
);
export const selectProgramPlanViewHasDuplicateOptions = createSelector(selectSelectedActivePlanList, (plans) => {
    const athenaPlans = plans.map((p) => p.athenaPlanId);
    const uniqAthenaMappings = new Set(athenaPlans);
    return uniqAthenaMappings.size !== athenaPlans.length;
});

export const selectSelectedProgramLoading = (state: RootState) => state.slidingFee.selectedProgram.loading;

export const selectIsProgramPlanPanelOpen = (state: RootState) => state.slidingFee.isProgramPlanPanelOpen;

export const selectSelectedProgramPlan = (state: RootState) => state.slidingFee.selectedProgramPlan;

export const selectSelectedCodeExclusion = (state: RootState) => state.slidingFee.selectedCodeExclusion;
export const selectSelectedCodeExclusionData = (state: RootState) => state.slidingFee.selectedCodeExclusion.data;
export const selectIsCodeExclusionPanelOpen = (state: RootState) => state.slidingFee.isCodeExclusionPanelOpen;
export const selectSelectedCodeExclusionIsNew = createSelector(
    selectSelectedProgramPlan,
    selectSelectedCodeExclusionData,
    (plan, data) => (plan.data?.codeExclusions ?? []).findIndex((exclusion) => exclusion.id === data?.id) === -1,
);
export const selectSelectedProgramPlanCodeExclusionList = (state: RootState) =>
    state.slidingFee.selectedProgramPlan.data?.codeExclusions;
export const selectSelectedProgramPlanCodeExclusionListDeleted = (state: RootState) =>
    state.slidingFee.toggleDeletedCodeExclusions;
export const selectSelectedCodeExclusionList = createSelector(
    selectSelectedProgramPlanCodeExclusionList,
    selectSelectedProgramPlanCodeExclusionListDeleted,
    (category, deleted) => {
        const list = map(category, (c) => c) as ICodeExclusion[];
        return !deleted ? list : list.filter((c) => !c.isDeleted);
    },
);

export const selectSelectedCategoryExclusion = (state: RootState) => state.slidingFee.selectedCategoryExclusion;
export const selectSelectedCategoryExclusionData = (state: RootState) => state.slidingFee.selectedCategoryExclusion.data;
export const selectIsCategoryExclusionPanelOpen = (state: RootState) => state.slidingFee.isCategoryExclusionPanelOpen;
export const selectSelectedCategoryExclusionIsNew = createSelector(
    selectSelectedProgramPlan,
    selectSelectedCategoryExclusionData,
    (plan, data) => (plan.data?.codeCategoryExclusions ?? []).findIndex((exclusion) => exclusion.id === data?.id) === -1,
);
export const selectSelectedProgramPlanCategoryExclusionList = (state: RootState) =>
    state.slidingFee.selectedProgramPlan.data?.codeCategoryExclusions;
export const selectSelectedProgramPlanCategoryExclusionListDeleted = (state: RootState) =>
    state.slidingFee.toggleDeletedCategoryExclusions;
export const selectSelectedCategoryExclusionList = createSelector(
    selectSelectedProgramPlanCategoryExclusionList,
    selectSelectedProgramPlanCategoryExclusionListDeleted,
    (category, deleted) => {
        const list = map(category, (c) => c) as ICodeCategoryExclusion[];
        return !deleted ? list : list.filter((c) => !c.isDeleted);
    },
);

export const selectSelectedCodeGroupExclusion = (state: RootState) => state.slidingFee.selectedGroupExclusion;
export const selectSelectedCodeGroupExclusionData = (state: RootState) => state.slidingFee.selectedGroupExclusion.data;
export const selectSelectedCodeGroupExclusionIsNew = createSelector(
    selectSelectedProgramPlan,
    selectSelectedCodeGroupExclusionData,
    (plan, data) => (plan.data?.codeRangeExclusions ?? []).findIndex((exclusion) => exclusion.id === data?.id) === -1,
);
export const selectSelectedProgramPlanRangeExclusionList = (state: RootState) =>
    state.slidingFee.selectedProgramPlan.data?.codeRangeExclusions;

export const selectSelectedProgramPlanRangeExclusionListDeleted = (state: RootState) =>
    state.slidingFee.toggleDeletedRangeExclusions;
export const selectSelectedRangeExclusionList = createSelector(
    selectSelectedProgramPlanRangeExclusionList,
    selectSelectedProgramPlanRangeExclusionListDeleted,
    (category, deleted) => {
        const list = map(category, (c) => c) as ICodeGroupExclusion[];
        return !deleted ? list : list.filter((c) => !c.isDeleted);
    },
);

export const selectIsCodeGroupExclusionPanelOpen = (state: RootState) => state.slidingFee.isGroupExclusionPanelOpen;

export const getSelectedProgramExclusions = createSelector(selectSelectedProgramPlan, (selectedPlan) => {
    const data = selectedPlan.data;
    if (data) {
        const { codeExclusions, codeCategoryExclusions, codeRangeExclusions } = data;
        return {
            codeExclusions: codeExclusions?.filter(isNotDeleted) ?? [],
            codeCategoryExclusions: codeCategoryExclusions?.filter(isNotDeleted) ?? [],
            codeRangeExclusions: codeRangeExclusions?.filter(isNotDeleted) ?? [],
        };
    } else {
        return undefined;
    }

    function isNotDeleted(item?: ICodeExclusion | ICodeCategoryExclusion | ICodeGroupExclusion) {
        return item ? !item.isDeleted : false;
    }
});

export const selectRangeExclusionIsValid = createSelector(
    [getSelectedProgramExclusions, selectSelectedCodeGroupExclusion],
    (allExclusions, selectedRangeExclusion) => {
        /**
         * 1. Cannot save range exclusion that does not have an end
         * 2. Cannot save range exclusion that's end is before beginning
         * 3. Cannot save range exclusion that overlaps another's dates && codes
         *
         */
        if (selectedRangeExclusion.data) {
            const { startCode, endCode, effectiveDate } = selectedRangeExclusion.data;
            const errors = [];

            // Missing end code for range
            if (endCode === '') {
                errors.push('Selected range missing an end code');
            }

            if (effectiveDate === '') {
                errors.push('Selected range missing an effective date ');
            }

            const startNumber = startCode?.split('D')[1];
            const endNumber = endCode?.split('D')[1];

            if (startNumber && endNumber) {
                // Start code is less than end code
                if (startNumber > endNumber) {
                    errors.push('Start Code should be less than End Code');
                }

                // Overlaps dates and codes

                const getRangeExclusions = allExclusions?.codeRangeExclusions
                    .filter((c) => c.id !== selectedRangeExclusion.data?.id)
                    .filter((c) => !c.isDeleted);

                const hasCodeOverlap = getRangeExclusions?.filter((exclusion) => {
                    const startToCompare = exclusion.startCode?.split('D')[1];
                    const endToCompare = exclusion.endCode?.split('D')[1];
                    if (startToCompare && endToCompare) {
                        const betweenStart = startNumber >= startToCompare && startNumber <= endToCompare;
                        const betweenEnd = endNumber >= startToCompare && endNumber <= endToCompare;
                        const compareNumbersAreBetwen = startNumber <= startToCompare && endNumber >= endToCompare;

                        return betweenStart || betweenEnd || compareNumbersAreBetwen;
                    } else {
                        return false;
                    }
                });

                const hasDateOverlap = hasCodeOverlap?.some((exclusion) => {
                    const isBetween = isDateBetween({
                        dateToCheck: effectiveDate,
                        start: exclusion.effectiveDate,
                        end: exclusion.expirationDate,
                    });

                    const checkUndefinedDates =
                        exclusion.effectiveDate && !effectiveDate
                            ? true
                            : effectiveDate && !exclusion.effectiveDate
                              ? true
                              : false;

                    return isBetween || checkUndefinedDates;
                });

                if (hasCodeOverlap && hasDateOverlap) {
                    errors.push('Current range conflicts with an existing set of data.');
                }
            }

            return errors;
        }
    },
);

export const selectSpecificCodeExclusionIsValid = createSelector(
    [getSelectedProgramExclusions, selectSelectedCodeExclusion],
    (allExclusions, selectedSpecificCodeExclusion) => {
        if (selectedSpecificCodeExclusion.data) {
            const { code, effectiveDate } = selectedSpecificCodeExclusion.data;
            const errors = [];

            const getcodeExclusions = allExclusions?.codeExclusions
                .filter((c) => c.id !== selectedSpecificCodeExclusion?.data?.id)
                .filter((c) => !c.isDeleted);
            const singleCode = code?.split('D')[1];

            if (effectiveDate === '') {
                errors.push('Selected code missing an effective date ');
            }

            const hasCodeOverlap = getcodeExclusions?.filter((exclusion) => {
                const codeToCompare = exclusion.code?.split('D')[1];
                if (codeToCompare) {
                    return codeToCompare === singleCode;
                } else {
                    return false;
                }
            });

            const hasDateOverlap = hasCodeOverlap?.some((exclusion) => {
                const isBetween = isDateBetween({
                    dateToCheck: effectiveDate,
                    start: exclusion.effectiveDate,
                    end: exclusion.expirationDate,
                });

                const checkUndefinedDates =
                    exclusion.effectiveDate && !effectiveDate ? true : effectiveDate && !exclusion.effectiveDate ? true : false;

                return isBetween || checkUndefinedDates;
            });

            if (hasCodeOverlap && hasDateOverlap) {
                errors.push('Current code conflicts with an existing set of data.');
            }
            return errors;
        }
    },
);
export const selectCategoryExclusionIsValid = createSelector(
    [getSelectedProgramExclusions, selectSelectedCategoryExclusion],
    (allExclusions, selectedCategoryExclusion) => {
        if (selectedCategoryExclusion.data) {
            const { category, effectiveDate } = selectedCategoryExclusion.data;
            const errors = [];

            const getcodeExclusions = allExclusions?.codeCategoryExclusions
                .filter((c) => c.id !== selectedCategoryExclusion.data?.id)
                .filter((c) => !c.isDeleted);

            if (effectiveDate === '') {
                errors.push('Selected category missing an effective date ');
            }

            const hasCodeOverlap = getcodeExclusions?.filter((exclusion) => {
                const categoryToCompare = exclusion.category;
                if (categoryToCompare) {
                    return categoryToCompare === category;
                } else {
                    return false;
                }
            });

            const hasDateOverlap = hasCodeOverlap?.some((exclusion) => {
                const isBetween = isDateBetween({
                    dateToCheck: effectiveDate,
                    start: exclusion.effectiveDate,
                    end: exclusion.expirationDate,
                });

                const checkUndefinedDates =
                    exclusion.effectiveDate && !effectiveDate ? true : effectiveDate && !exclusion.effectiveDate ? true : false;

                return isBetween || checkUndefinedDates;
            });

            if (hasCodeOverlap && hasDateOverlap) {
                errors.push('Current category conflicts with an existing set of data.');
            }
            return errors;
        }
    },
);

export default reducer;
