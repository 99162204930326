import { createSelector } from '@reduxjs/toolkit';
import { ITenantUser } from 'api/models/tenantAccount.model';
import { map } from 'lodash';
import { selectUsersAsList } from 'state/slices/users/users.selectors';
import { RootState } from 'state/store';

export const selectSubscriptionUsers = (state: RootState) => state.subscriptions.users;
export const selectSubscriptionsUsers = (state: RootState) => state.subscriptions.users.data;

export const selectSubscriptionUsersAProvider = (state: RootState) => state.subscriptions.users.isProvider;
export const selectSubscriptionUsersActive = (state: RootState) => state.subscriptions.users.isActive;
export const selectSelectedUser = (state: RootState) => state.subscriptions.users.selectedUser;

export const selectSubscriptionUsersAsList = (state: RootState) =>
    map(state.subscriptions.users.data, (user) => {
        return user;
    }) as ITenantUser[];

export const selectSubscriptionUsersAsProviderList = createSelector(
    [selectSubscriptionsUsers, selectSubscriptionUsersAProvider, selectSubscriptionUsersActive],
    (users, isProvider, isActive) => {
        const list = map(users, (u) => u) as ITenantUser[];

        if (isProvider && isActive) {
            return list.filter((u) => u.identity.providerId?.length && !u.isDeleted && u.identity.providerId !== '000');
        }
        if (isProvider) {
            return list.filter((u) => u.identity.providerId?.length && u.identity.providerId !== '000');
        }
        if (!isActive) {
            return list.filter((u) => !u.isDeleted);
        }

        return list;
    },
);
export const usersNotInSubscription = createSelector([selectSubscriptionUsersAsList, selectUsersAsList], (subUsers, users) => {
    return users.filter((u) => !u.isDeleted).filter((u) => subUsers.findIndex((s) => s.identity.id === u.identity.id) === -1);
});

// Checks to see if selectedUser.providerId is in use by another user
export const selectProviderIdInUse = createSelector([selectSubscriptionUsersAsList, selectSelectedUser], (subUsers, user) => {
    // Provider Id is not in use if it is not set or is set to 000
    if (!user?.identity.providerId || user?.identity.providerId === '000') {
        return false;
    }
    // See if there is another user with the same providerId
    return subUsers.some((u) => u.identity.providerId === user.identity.providerId && u.identity.id !== user.identity.id);
});
