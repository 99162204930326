import { LoadingStatus } from 'interfaces/loading-statuses';
import SliceLocalStorage from 'utils/sliceLocalStorage';
import { setExpanded, SubscriptionState } from '../subscriptions.slice';

const subscriptionLocalStorage = new SliceLocalStorage('athenaOne-membership', [setExpanded?.type]);

const isExpanded: boolean = subscriptionLocalStorage.getItem(setExpanded?.type)
    ? subscriptionLocalStorage.getItem(setExpanded?.type)
    : true;

export const initialSubscriptionsState: SubscriptionState = {
    isExpanded: isExpanded,
    users: {
        data: {},
        saving: LoadingStatus.Idle,
        loading: LoadingStatus.Idle,
        isProvider: false,
        isActive: false,
    },
};

export default subscriptionLocalStorage;
